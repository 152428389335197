import React from "react"
import { Link } from "gatsby"

import { logo } from "../utils/images"

const Header = () => (
  <header>
    <h1>
      <Link to="/">
        <img src={logo} alt="Comox Valley Metal Products" />
      </Link>
    </h1>

    <nav>
      <a href="tel:12503382775">1-250-338-2775</a>
      <Link to="/metal-roofing">ROOFING</Link>
      <Link to="/interior-exterior-cladding">CLADDING</Link>
      <Link to="/fencing-flashing">FLASHING</Link>
    </nav>
  </header>
)

export default Header
