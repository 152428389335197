import React, { useState } from "react"

const Contact = props => {
  const { product = "" } = props
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState(product)
  const [status, setStatus] = useState("")

  const send = event => {
    event.preventDefault()

    if (status === "") {
      fetch(
        "https://us-central1-cv-metal-fabrication.cloudfunctions.net/app/add_client",
        {
          method: "POST",
          body: JSON.stringify({ name, email, phone, message }),
          headers: {
            "content-type": "application/json",
          },
        }
      )
        .then(resp => resp.json())
        .then(() => setStatus("sent"))
        .catch(_e => setStatus("error"))
    }
  }

  return (
    <article className="text-section" id="contact">
      <p>Metal roofing made easy, contact us for more infomation.</p>

      <form className="contact-form">
        <input
          type="text"
          className="full-width"
          name="name"
          placeholder="Name"
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <input
          type="phone"
          name="phone"
          placeholder="Phone"
          value={phone}
          onChange={event => setPhone(event.target.value)}
        />
        <input
          type="text"
          className="full-width"
          name="message"
          placeholder="Message"
          value={message}
          onChange={event => setMessage(event.target.value)}
        />
        {status !== "sent" && (
          <input
            disabled={status === "sent"}
            type="submit"
            defaultValue="Submit"
            onClick={send}
          />
        )}
        {status === "sent" && (
          <p className="full-width">Thanks, we'll be in touch soon!</p>
        )}
      </form>
    </article>
  )
}

export default Contact
