// homepage
import logo from "../images/assets/logo.svg"
import header from "../images/homepage/header.jpg"
import fencing_flashing from "../images/homepage/fencing-flashing.jpg"
import interior_exterior_cladding from "../images/homepage/interior-exterior-cladding.jpg"
import metal_roofing from "../images/homepage/metal-roofing.jpg"

// product pages
import block_fencing from "../images/product_pages/Block Fencing.jpg"
import grey_siding_and from "../images/product_pages/Grey Siding and Black Roofing - Cladding.jpg"
import metal_roof_and from "../images/product_pages/Metal Roof and Siding - Cladding.jpg"
import orange_and_grey from "../images/product_pages/Orange and Grey Siding - Cladding.jpg"
import orange_siding from "../images/product_pages/Orange Siding - Cladding.jpg"
import pioneer_memorial from "../images/product_pages/Pioneer Memorial Park Fencing.jpg"
import red_and_grey from "../images/product_pages/Red and Grey Siding and Silver - Cladding.jpg"
import silver_metal from "../images/product_pages/Silver Metal - Roofing.jpg"

// photo pages (fencing)
import fencing_1 from "../images/photo_pages/fencing-flashing/1.jpg"
import fencing_2 from "../images/photo_pages/fencing-flashing/2.jpg"
import fencing_3 from "../images/photo_pages/fencing-flashing/3.jpg"
import fencing_4 from "../images/photo_pages/fencing-flashing/4.jpg"
import fencing_5 from "../images/photo_pages/fencing-flashing/5.jpg"
import fencing_6 from "../images/photo_pages/fencing-flashing/6.jpg"
import fencing_7 from "../images/photo_pages/fencing-flashing/7.jpg"
import fencing_8 from "../images/photo_pages/fencing-flashing/8.jpg"
import fencing_9 from "../images/photo_pages/fencing-flashing/9.jpg"

// photo pages (cladding)
import cladding_1 from "../images/photo_pages/cladding/1.jpg"
import cladding_2 from "../images/photo_pages/cladding/2.jpg"
import cladding_3 from "../images/photo_pages/cladding/3.jpg"
import cladding_4 from "../images/photo_pages/cladding/4.jpg"
import cladding_5 from "../images/photo_pages/cladding/5.jpg"
import cladding_6 from "../images/photo_pages/cladding/6.jpg"
import cladding_7 from "../images/photo_pages/cladding/7.jpg"
import cladding_8 from "../images/photo_pages/cladding/8.jpg"
import cladding_9 from "../images/photo_pages/cladding/9.jpg"
import cladding_10 from "../images/photo_pages/cladding/10.jpg"
import cladding_11 from "../images/photo_pages/cladding/11.jpg"
import cladding_12 from "../images/photo_pages/cladding/12.jpg"
import cladding_13 from "../images/photo_pages/cladding/13.jpg"
import cladding_14 from "../images/photo_pages/cladding/14.jpg"
import cladding_15 from "../images/photo_pages/cladding/15.jpg"
import cladding_16 from "../images/photo_pages/cladding/16.jpg"
import cladding_17 from "../images/photo_pages/cladding/17.jpg"
import cladding_18 from "../images/photo_pages/cladding/18.jpg"
import cladding_19 from "../images/photo_pages/cladding/19.jpg"
import cladding_20 from "../images/photo_pages/cladding/20.jpg"
import cladding_21 from "../images/photo_pages/cladding/21.jpg"
import cladding_22 from "../images/photo_pages/cladding/22.jpg"
import cladding_23 from "../images/photo_pages/cladding/23.jpg"
import cladding_24 from "../images/photo_pages/cladding/24.jpg"

// photo pages (roofing)
import roofing_1 from "../images/photo_pages/roofing/1.jpg"
import roofing_2 from "../images/photo_pages/roofing/2.jpg"
import roofing_3 from "../images/photo_pages/roofing/3.jpg"
import roofing_4 from "../images/photo_pages/roofing/4.jpg"
import roofing_5 from "../images/photo_pages/roofing/5.jpg"
import roofing_6 from "../images/photo_pages/roofing/6.jpg"
import roofing_7 from "../images/photo_pages/roofing/7.jpg"
import roofing_8 from "../images/photo_pages/roofing/8.jpg"
import roofing_9 from "../images/photo_pages/roofing/9.jpg"
import roofing_10 from "../images/photo_pages/roofing/10.jpg"
import roofing_11 from "../images/photo_pages/roofing/11.jpg"
import roofing_12 from "../images/photo_pages/roofing/12.jpg"
import roofing_13 from "../images/photo_pages/roofing/13.jpg"
import roofing_14 from "../images/photo_pages/roofing/14.jpg"
import roofing_15 from "../images/photo_pages/roofing/15.jpg"
import roofing_16 from "../images/photo_pages/roofing/16.jpg"
import roofing_17 from "../images/photo_pages/roofing/17.jpg"
import roofing_18 from "../images/photo_pages/roofing/18.jpg"

export {
  logo,
  header,
  fencing_flashing,
  interior_exterior_cladding,
  metal_roofing,
  block_fencing,
  grey_siding_and,
  metal_roof_and,
  orange_and_grey,
  orange_siding,
  pioneer_memorial,
  red_and_grey,
  silver_metal,
  fencing_1,
  fencing_2,
  fencing_3,
  fencing_4,
  fencing_5,
  fencing_6,
  fencing_7,
  fencing_8,
  fencing_9,
  cladding_1,
  cladding_2,
  cladding_3,
  cladding_4,
  cladding_5,
  cladding_6,
  cladding_7,
  cladding_8,
  cladding_9,
  cladding_10,
  cladding_11,
  cladding_12,
  cladding_13,
  cladding_14,
  cladding_15,
  cladding_16,
  cladding_17,
  cladding_18,
  cladding_19,
  cladding_20,
  cladding_21,
  cladding_22,
  cladding_23,
  cladding_24,
  roofing_1,
  roofing_2,
  roofing_3,
  roofing_4,
  roofing_5,
  roofing_6,
  roofing_7,
  roofing_8,
  roofing_9,
  roofing_10,
  roofing_11,
  roofing_12,
  roofing_13,
  roofing_14,
  roofing_15,
  roofing_16,
  roofing_17,
  roofing_18,
}
