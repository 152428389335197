import React from "react"
import Fade from "react-reveal"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Contact from "../components/contact"
import ImagePanel from "../components/imagepanel"

import {
  header,
  fencing_flashing,
  interior_exterior_cladding,
  metal_roofing,
} from "../utils/images"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <article className="slideshow-section">
        <img className="hero-image" src={header} alt="Man Creating Blueprint" />
        <div className="section__overlay">
          <Fade top>
            <h2 className="section__title">
              <span className="with-underline">Our history</span>
            </h2>
          </Fade>
        </div>
      </article>
      <article className="text-section">
        <p>
          CV Metal Products is a new era for our company, originally named
          Claire Supply and later named Comox Valley Wholesale and run by the
          wonderful Claire family for over 30 years, supplying the Comox Valley
          with everything you could think of, from fire places that warmed our
          homes, roofing supplies that kept them dry to fertilizer which fed and
          provided for our families and this amazing community. It was purchased
          in 2014 by Barry Dennis, who proudly ran and built Mission Possible
          Appliance for over 20 years and later passed it on to 3 of his 4 sons.
          After backing away from the appliance business he successfully ran an
          alarm franchise servicing the South Island before that was taken over
          by the head company.
        </p>
        <p>
          In his pursuit of better, small local business he found Comox Valley
          Wholesale, a strong, local, family business in an industry that needed
          some support, and purchased Comox Valley Wholesale and has been
          running it with, myself, Luke Dennis since Early 2014. We are proud
          supporters of small business and the people in our community, we
          strive to provide the best service in a timely manner and provide as
          much knowledge and understanding for the industry we service.
        </p>
      </article>

      <div id="products">
        <ImagePanel
          image={metal_roofing}
          title="Metal Roofing"
          link="/metal-roofing"
        />

        <ImagePanel
          image={interior_exterior_cladding}
          title="Interior and Exterior Cladding"
          link="/interior-exterior-cladding"
        />

        <ImagePanel
          image={fencing_flashing}
          title="Fencing and Flashing"
          link="/fencing-flashing"
        />
      </div>

      <Contact />
    </div>
  </Layout>
)

export default IndexPage
