import React from "react"
import { Link } from "gatsby"

import { logo } from "../utils/images"

const Footer = () => (
  <footer>
    <div className="large">
      <img src={logo} alt="Comox Valley Metal Products" />
    </div>

    <div className="linklists">
      <ul>
        <li>
          <a href="tel:12503382775">1-250-338-2775</a>
        </li>
        <li>1886 Waveland Rd.</li>
        <li>Comox, BC</li>
        <li>V9M 4E5</li>
      </ul>

      <ul>
        <li>
          <Link to="/#products">Products</Link>
        </li>
      </ul>

      <ul>
        <li>
          <a
            href="https://www.facebook.com/roofingsupplier/"
            target="_blank"
            rel="noreferrer nofollow noopener"
          >
            Facebook
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
