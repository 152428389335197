import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal"

const ImagePanel = props => {
  const { image, title, link } = props

  return (
    <article className="project-section has-content">
      <img className="hero-image" src={image} alt={title} />
      <div className="section__overlay background">
        <Fade top>
          <h2 className="section__title">
            <Link to={link} className="with-underline">
              {title}
            </Link>
          </h2>
        </Fade>

        <p className="section__content">
          <Link className="btn" to={link}>
            See more
          </Link>
        </p>
      </div>
    </article>
  )
}

export default ImagePanel
